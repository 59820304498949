import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private apiUrl = 'https://api.rastreamed.com.br';

  constructor(private http: HttpClient) { }

  getPatientData(code: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/person/findByCode/${code}`);
  }
  public find(userId: any): Observable<any> {
    const url = `${this.apiUrl}/api/person/${userId}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get<any>(url, { headers });
  }
}
