import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import jsPDF from 'jspdf';
import { Router } from '@angular/router';
@Component({
  selector: 'app-member-medical-record',
  templateUrl: './member-medical-record.component.html',
  styleUrls: ['./member-medical-record.component.scss']
})
export class MemberMedicalRecordComponent implements OnInit {
  medicalRecordForm: FormGroup;
  showPrintableView = false;
  formData: any;
  showAuxiliaryEquipment = false;
  showPhysicalHealth = false;
  showMedications = false;
  showAllergies = false;
  showPhysicalImpediment = false;
  showFoodRestrictions = false;
  showBehavioralDisorder = false;
  showBehavioralMedication = false;
  showPsychologistFollowUp = false;
  showMedicalFollowUp = false;
  showOtherProfessionalFollowUp = false;
  showDisability = false;
  showSelfAggression = false;
  showOthersAggression = false;
  showPsychomotorAgitation = false;
  showVerbalInstructions = false;
  showEscapeTendency = false;
  showSelfDestructiveBehavior = false;
  showAnxietyCrisis = false;
  showCommunicationDifficulty = false;
  showFeedingDifficulty = false;
  showSleepDifficulty = false;

  constructor(private fb: FormBuilder, private http: HttpClient,private router: Router) {
    this.medicalRecordForm = this.fb.group({
      name: [''],
      cpf: [''],
      gender: [''],
      birthDate: [''],
      registration: [''],
      code: [''],
      height: [''],
      weight: [''],
      bloodType: [''],
      rhFactor: [''],
      auxiliaryEquipment: [''],
      equipment: this.fb.group({
        oculos: [false],
        lentes: [false],
        dentarios: [false],
        sondas: [false],
        marcapasso: [false],
        audicao: [false],
        bomba: [false],
        outros: [''],
        otherEquipment:['']
      }),
      allergies: [''],
      allergyDetails: this.fb.group({
        inseto: [''],
        medicamento: [''],
        plantas: [''],
        alimentos: [''],
        acaros: [''],
        fungos: [''],
        outros: ['']
      }),
      physicalHealth: [''],
      healthIssue: this.fb.group({
        asma: [false],
        rinite: [false],
        hipertensao: [false],
        diabetes: [false],
        convulsoes: [false],
        dermatologicos: [false],
        cardiacos: [false],
        renais: [false],
        reumatologicos: [false],
        hematologicos: [false],
        outros: ['']
      }),
      medications: [''],
      medicationList: [''],
      physicalImpediment: [''],
      physicalImpedimentDetails: [''],
      foodRestrictions: [''],
      foodRestrictionDetails: [''],
      swimmingAbility: [''],
      behavioralDisorder: [''],
      behavioralDisorderDetails: [''],
      behavioralMedication: [''],
      behavioralMedicationDetails: [''],
      psychologistFollowUp: [''],
      psychologistFollowUpDetails: [''],
      medicalFollowUp: [''],
      medicalFollowUpDetails: [''],
      otherProfessionalFollowUp: [''],
      otherProfessionalFollowUpDetails: [''],
      hasDisability: [''],
      disabilityDetails: this.fb.group({
        fisica: [''],
        visual: [''],
        auditiva: [''],
        intelectual: [''],
        autismo: ['']
      }),
      selfAggression: [''],
      selfAggressionDetails: [''],
      othersAggression: [''],
      othersAggressionDetails: [''],
      psychomotorAgitation: [''],
      psychomotorAgitationDetails: [''],
      verbalInstructions: [''],
      verbalInstructionsDetails: [''],
      escapeTendency: [''],
      escapeTendencyDetails: [''],
      selfDestructiveBehavior: [''],
      selfDestructiveBehaviorDetails: [''],
      anxietyCrisis: [''],
      anxietyCrisisDetails: [''],
      communicationDifficulty: [''],
      communicationDifficultyDetails: [''],
      feedingDifficulty: [''],
      feedingDifficultyDetails: [''],
      sleepDifficulty: [''],
      sleepDifficultyDetails: ['']
    });
  }

  ngOnInit(): void { }

  toggleAuxiliaryEquipment(event: any): void {
    this.showAuxiliaryEquipment = event.target.value === 'sim';
  }

  togglePhysicalHealth(event: any): void {
    this.showPhysicalHealth = event.target.value === 'sim';
  }

  toggleMedications(event: any): void {
    this.showMedications = event.target.value === 'sim';
  }

  toggleAllergies(event: any): void {
    this.showAllergies = event.target.value === 'sim';
  }

  togglePhysicalImpediment(event: any): void {
    this.showPhysicalImpediment = event.target.value === 'sim';
  }

  toggleFoodRestrictions(event: any): void {
    this.showFoodRestrictions = event.target.value === 'sim';
  }

  toggleBehavioralDisorder(event: any): void {
    this.showBehavioralDisorder = event.target.value === 'sim';
  }

  toggleBehavioralMedication(event: any): void {
    this.showBehavioralMedication = event.target.value === 'sim';
  }

  togglePsychologistFollowUp(event: any): void {
    this.showPsychologistFollowUp = event.target.value === 'sim';
  }

  toggleMedicalFollowUp(event: any): void {
    this.showMedicalFollowUp = event.target.value === 'sim';
  }

  toggleOtherProfessionalFollowUp(event: any): void {
    this.showOtherProfessionalFollowUp = event.target.value === 'sim';
  }

  toggleDisability(event: any): void {
    this.showDisability = event.target.value === 'sim';
  }

  toggleSelfAggression(event: any): void {
    this.showSelfAggression = event.target.value === 'sim';
  }

  toggleOthersAggression(event: any): void {
    this.showOthersAggression = event.target.value === 'sim';
  }

  togglePsychomotorAgitation(event: any): void {
    this.showPsychomotorAgitation = event.target.value === 'sim';
  }

  toggleVerbalInstructions(event: any): void {
    this.showVerbalInstructions = event.target.value === 'sim';
  }

  toggleEscapeTendency(event: any): void {
    this.showEscapeTendency = event.target.value === 'sim';
  }

  toggleSelfDestructiveBehavior(event: any): void {
    this.showSelfDestructiveBehavior = event.target.value === 'sim';
  }

  toggleAnxietyCrisis(event: any): void {
    this.showAnxietyCrisis = event.target.value === 'sim';
  }

  toggleCommunicationDifficulty(event: any): void {
    this.showCommunicationDifficulty = event.target.value === 'sim';
  }

  toggleFeedingDifficulty(event: any): void {
    this.showFeedingDifficulty = event.target.value === 'sim';
  }

  toggleSleepDifficulty(event: any): void {
    this.showSleepDifficulty = event.target.value === 'sim';
  }

 
  submitForm(): void {
    this.formData = this.medicalRecordForm.value;
    console.log(this.medicalRecordForm)
      this.http.post('https://api.rastreamed.com.br/api/medicalData', this.formData).subscribe({
        next: response => {
          this.success = true;
          this.showModal = true
        },
        error: error =>{
          this.success = false;
          this.showModal = true
        }
      });
    
  }
  showModal: boolean = false;

  success: boolean = false;



  closeModal(): void {
    this.showModal = false;
    if(this.success){
      this.router.navigate(['/']);
    }
  }
  /*
  
    downloadPDF(): void {
      const doc = new jsPDF();
  
      doc.setFontSize(16);
      doc.text('Ficha Médica', 20, 20);
  
      let yPosition = 30;
      for (const [key, value] of Object.entries(this.formData)) {
        const displayText = typeof value === 'object' ? JSON.stringify(value) : value;
        doc.setFontSize(12);
        doc.text(`${key}: ${displayText}`, 20, yPosition);
        yPosition += 10;
  
        if (yPosition > 280) {
          doc.addPage();
          yPosition = 20;
        }
      }
  
      doc.save('ficha_medica.pdf');
    }
  
  */
}
