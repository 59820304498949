<div class="presentation-container">
    <div class="app-preview">
        <img src="../../assets/images/rastreamed-sample.png" alt="App Login Screen" class="app-image" />
    </div>
    <div class="text-content">
        <img src="../../assets/images/logo.png" alt="RastreaMed Logo" class="logo" />
        <p>
            Como seria ter em suas mãos, sua Saúde Rastreada, ou seja, em qualquer atendimento ter acesso ao seu Cartão
            de Vacinação, Medicamentos, Exames e muito mais...!!!
        </p>
        <p>
            A RastreaMed está preparando tudo isso para Você e sua Família.
        </p>
        <p>
            Nos acompanhem para saber mais.
        </p>
    </div>
</div>