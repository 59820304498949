import { Component } from '@angular/core';
import { SearchService } from '../search.service'; // Importe o serviço

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  searchCode: string = ''; 
  patientData: any = null; 
  showErrorModal: boolean = false; 

  constructor(private searchService: SearchService) {}

  onSearch(): void {
    if (this.searchCode) { 
      this.searchService.getPatientData(this.searchCode).subscribe(
        (data) => {
          if (data) {
            this.patientData = data; 
          } else {
            this.showErrorModal = true;
          }
        },
        (error) => {
          console.error('Erro ao buscar dados:', error);
          this.showErrorModal = true;
        }
      );
    }
  }

  closeModal(): void {
    this.showErrorModal = false; 
  }
}
