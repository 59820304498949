import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private apiUrl = 'https://api.rastreamed.com.br';

  constructor(private http: HttpClient) { }

  getPatientData(code: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/person/findByCode/${code}`);
  }
}
