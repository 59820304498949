<div class="medical-record-header">
  <div class="logo-section">
    <img src="assets/images/logo-escoteiros.webp" alt="Logo Escoteiros do Brasil" class="logo">
    <div class="header-text">
      <h3>Escoteiros do Brasil</h3>
      <p>PAXTU - Sistema de Informações e Gerenciamento de Unidades Escoteiras</p>
    </div>
    <img src="assets/images/logo-papa-leguas.png" alt="Logo Escoteiros do Brasil" class="logo2">
  </div>
  <div class="unit-section">
    <p>148/MG - PAPA-LÉGUAS</p>
  </div>
</div>
<div class="medical-record-form">
  <h2 class="title">Ficha Médica</h2>

  <form [formGroup]="medicalRecordForm" (ngSubmit)="submitForm()">
    <div class="form-group">
      <label for="name">Nome:</label>
      <input type="text" id="name" formControlName="name" class="input-text" required>
    </div>
    <div class="form-group">
      <label for="cpf">CPF:</label>
      <input type="text" id="cpf" formControlName="cpf" class="input-text" required>
    </div>

    <div class="form-group">
      <label for="registration">Codigo da pulseira:</label>
      <input type="text" id="registration" formControlName="registration" class="input-text">
    </div>

    <div class="form-group">
      <label for="gender">Sexo:</label>
      <select id="gender" formControlName="gender" class="select-input" required>
        <option value="">Selecione</option>
        <option value="M">Masculino</option>
        <option value="F">Feminino</option>
      </select>
    </div>

    <div class="form-group">
      <label for="birthDate">Data de Nascimento:</label>
      <input type="date" id="birthDate" formControlName="birthDate" class="input-text" required>
    </div>

    <div class="form-group">
      <label for="code">Registro:</label>
      <input type="text" id="code" formControlName="code" class="input-text">
    </div>

    <div class="form-group">
      <label for="height">Altura (cm):</label>
      <input type="number" id="height" formControlName="height" class="input-text">
    </div>

    <div class="form-group">
      <label for="weight">Peso (kg):</label>
      <input type="number" id="weight" formControlName="weight" class="input-text">
    </div>

    <div class="form-group">
      <label for="bloodType">Tipo Sanguíneo:</label>
      <input type="text" id="bloodType" formControlName="bloodType" class="input-text">
    </div>

    <div class="form-group">
      <label for="rhFactor">Fator RH:</label>
      <select id="rhFactor" formControlName="rhFactor" class="select-input">
        <option value="">Selecione</option>
        <option value="Positivo">Positivo</option>
        <option value="Negativo">Negativo</option>
      </select>
    </div>

    <!-- Equipamentos de Auxílio -->
    <h3 class="section-title">Equipamentos de Auxílio</h3>
    <div class="form-group">
      <label>Utiliza algum equipamento de auxílio?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="auxiliaryEquipment" (change)="toggleAuxiliaryEquipment($event)"
            value="nao" required> Não</label>
        <label><input type="radio" formControlName="auxiliaryEquipment" (change)="toggleAuxiliaryEquipment($event)"
            value="sim">
          Sim</label>
      </div>
    </div>
    <div class="form-group" *ngIf="showAuxiliaryEquipment" formGroupName="equipment">
      <div class="checkbox-group">
        <label><input type="checkbox" id="oculos" formControlName="oculos" value="oculos"> Óculos</label>
        <label><input type="checkbox" id="lentes" formControlName="lentes" value="lentes"> Lentes de Contato</label>
        <label><input type="checkbox" id="dentarios" formControlName="dentarios" value="dentarios"> Aparelhos
          Dentários</label>
        <label><input type="checkbox" id="sondas" formControlName="sondas" value="sondas"> Sondas</label>
        <label><input type="checkbox" id="marcapasso" formControlName="marcapasso" value="marcapasso">
          Marcapasso</label>
        <label><input type="checkbox" id="audicao" formControlName="audicao" value="audicao"> Aparelhos de
          Audição</label>
        <label><input type="checkbox" id="bomba" formControlName="bomba" value="bomba"> Bomba de Insulina</label>
        <label><input type="checkbox" id="outros_equipamento" formControlName="outros" value="outros"> Outros</label>
      </div>
      <input type="text" id="otherEquipment" formControlName="otherEquipment" class="textarea-input"
        placeholder="Cite outros">
    </div>

    <!-- Saúde Física -->
    <h3 class="section-title">Saúde Física</h3>
    <div class="form-group">
      <label>Possui algum problema de saúde física?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="physicalHealth" (change)="togglePhysicalHealth($event)" value="nao"
            required>
          Não</label>
        <label><input type="radio" formControlName="physicalHealth" (change)="togglePhysicalHealth($event)" value="sim">
          Sim</label>
      </div>
    </div>
    <div class="form-group" *ngIf="showPhysicalHealth" formGroupName="healthIssue">
      <div class="checkbox-group">
        <label><input type="checkbox" id="asma" formControlName="asma" value="asma"> Asma ou Bronquite</label>
        <label><input type="checkbox" id="rinite" formControlName="rinite" value="rinite"> Rinite ou Sinusite</label>
        <label><input type="checkbox" id="hipertensao" formControlName="hipertensao" value="hipertensao">
          Hipertensão</label>
        <label><input type="checkbox" id="diabetes" formControlName="diabetes" value="diabetes"> Diabetes</label>
        <label><input type="checkbox" id="convulsoes" formControlName="convulsoes" value="convulsoes"> Convulsões ou
          Epilepsia</label>
        <label><input type="checkbox" id="dermatologicos" formControlName="dermatologicos" value="dermatologicos">
          Problemas
          Dermatológicos</label>
        <label><input type="checkbox" id="cardiacos" formControlName="cardiacos" value="cardiacos"> Problemas
          Cardíacos</label>
        <label><input type="checkbox" id="renais" formControlName="renais" value="renais"> Problemas Renais</label>
        <label><input type="checkbox" id="reumatologicos" formControlName="reumatologicos" value="reumatologicos">
          Problemas
          Reumatológicos</label>
        <label><input type="checkbox" id="hematologicos" formControlName="hematologicos" value="hematologicos">
          Problemas
          Hematológicos</label>
        <label><input type="checkbox" id="outros_problemas_saude" formControlName="outros" value="outros">
          Outros</label>
      </div>
      <input type="text" id="otherHealthIssues" formControlName="otherHealthIssues" class="textarea-input"
        placeholder="Cite outros">
    </div>

    <!-- Medicamentos em Uso -->
    <h3 class="section-title">Medicamentos em Uso</h3>
    <div class="form-group">
      <label>Faz uso de medicamentos?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="medications" (change)="toggleMedications($event)" value="nao"
            required>
          Não</label>
        <label><input type="radio" formControlName="medications" (change)="toggleMedications($event)" value="sim">
          Sim</label>
      </div>
    </div>
    <div class="form-group" *ngIf="showMedications">
      <label>O jovem tem autonomia e está treinado para administrar sua medicação sozinho?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="canApply" value="nao" required> Não</label>
        <label><input type="radio" formControlName="canApply" value="sim"> Sim</label>
      </div>
      <label>Permite administrar medicamentos por profissionais em saúde que atuam no Grupo?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="allowsToApply" value="nao" required> Não</label>
        <label><input type="radio" formControlName="allowsToApply" value="sim"> Sim</label>
      </div>
      <textarea formControlName="medicationList" id="medicationList" class="textarea-input"
        placeholder="Se necessário, listar medicamentos"></textarea>
    </div>

    <!-- Alergias -->
    <h3 class="section-title">Alergias</h3>
    <div class="form-group">
      <label>Possui alergias?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="allergies" (change)="toggleAllergies($event)" value="nao" required>
          Não</label>
        <label><input type="radio" formControlName="allergies" (change)="toggleAllergies($event)" value="sim">
          Sim</label>
      </div>
    </div>
    <div class="form-group" *ngIf="showAllergies" formGroupName="allergyDetails">
      <div class="checkbox-group">
        <label><input type="checkbox" id="inseto" formControlName="inseto" value="inseto"> Picada de Inseto</label>

        <input type="text" formControlName="insectAllergyDetails" class="input-text" placeholder="Cite detalhes">

        <label><input type="checkbox" id="medicamento" formControlName="medicamento" value="medicamento">
          Medicamentos</label>

        <input type="text" formControlName="medicationAllergyDetails" class="input-text" placeholder="Cite detalhes">

        <label><input type="checkbox" id="plantas" formControlName="plantas" value="plantas"> Plantas</label>

        <input type="text" formControlName="plantAllergyDetails" class="input-text" placeholder="Cite detalhes">
        <label><input type="checkbox" id="alimentos" formControlName="alimentos" value="alimentos"> Alimentos</label>

        <input type="text" formControlName="foodAllergyDetails" class="input-text" placeholder="Cite detalhes">

        <label><input type="checkbox" id="acaros" formControlName="acaros" value="acaros"> Ácaros</label>
        <input type="text" formControlName="acarAllergyDetails" class="input-text" placeholder="Cite detalhes">
        <label><input type="checkbox" id="fungos" formControlName="fungos" value="fungos"> Fungos</label>
        <input type="text" formControlName="fungusAllergyDetails" class="input-text" placeholder="Cite detalhes">
        <label><input type="checkbox" id="outros_alergia" formControlName="outros" value="outros"> Outros</label>
        <input type="text" formControlName="otherAllergyDetails" class="input-text" placeholder="Cite outros">
      </div>
    </div>

    <!-- Informações Gerais -->
    <h3 class="section-title">Informações Gerais</h3>
    <div class="form-group">
      <label>Possui impedimento físico?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="physicalImpediment" (change)="togglePhysicalImpediment($event)"
            value="nao" required> Não</label>
        <label><input type="radio" formControlName="physicalImpediment" (change)="togglePhysicalImpediment($event)"
            value="sim">
          Sim</label>
      </div>
      <input type="text" id="physicalImpedimentDetails" formControlName="physicalImpedimentDetails" class="input-text"
        *ngIf="showPhysicalImpediment" placeholder="Cite detalhes">
    </div>

    <div class="form-group">
      <label>Restrições a alimentos?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="foodRestrictions" (change)="toggleFoodRestrictions($event)"
            value="nao" required> Não</label>
        <label><input type="radio" formControlName="foodRestrictions" (change)="toggleFoodRestrictions($event)"
            value="sim">
          Sim</label>
      </div>
      <input type="text" id="foodRestrictionDetails" formControlName="foodRestrictionDetails" class="input-text"
        *ngIf="showFoodRestrictions" placeholder="Cite detalhes">
    </div>

    <div class="form-group">
      <label>Sabe nadar?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="swimmingAbility" id="swimmingAbilityNo" value="nao" required>
          Não</label>
        <label><input type="radio" formControlName="swimmingAbility" id="swimmingAbilityYes" value="sim"> Sim</label>
      </div>
    </div>

    <!-- Saúde Mental -->
    <h3 class="section-title">Saúde Mental</h3>
    <div class="form-group">
      <label>Apresenta Distúrbio de Comportamento?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="behavioralDisorder" (change)="toggleBehavioralDisorder($event)"
            value="nao" required> Não</label>
        <label><input type="radio" formControlName="behavioralDisorder" (change)="toggleBehavioralDisorder($event)"
            value="sim">
          Sim</label>
      </div>
      <input type="text" id="behavioralDisorderDetails" formControlName="behavioralDisorderDetails" class="input-text"
        *ngIf="showBehavioralDisorder" placeholder="Cite detalhes">
    </div>

    <div class="form-group">
      <label>Faz uso de medicação para este transtorno?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="behavioralMedication" (change)="toggleBehavioralMedication($event)"
            value="nao" required> Não</label>
        <label><input type="radio" formControlName="behavioralMedication" (change)="toggleBehavioralMedication($event)"
            value="sim"> Sim</label>
      </div>
      <input type="text" id="behavioralMedicationDetails" formControlName="behavioralMedicationDetails"
        class="input-text" *ngIf="showBehavioralMedication" placeholder="Cite detalhes">
    </div>

    <div class="form-group">
      <label>Faz acompanhamento com psicólogo?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="psychologistFollowUp" (change)="togglePsychologistFollowUp($event)"
            value="nao" required> Não</label>
        <label><input type="radio" formControlName="psychologistFollowUp" (change)="togglePsychologistFollowUp($event)"
            value="sim"> Sim</label>
      </div>
      <input type="text" id="psychologistFollowUpDetails" formControlName="psychologistFollowUpDetails"
        class="input-text" *ngIf="showPsychologistFollowUp" placeholder="Cite detalhes">
    </div>

    <div class="form-group">
      <label>Faz acompanhamento com médico?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="medicalFollowUp" (change)="toggleMedicalFollowUp($event)"
            value="nao" required>
          Não</label>
        <label><input type="radio" formControlName="medicalFollowUp" (change)="toggleMedicalFollowUp($event)"
            value="sim">
          Sim</label>
      </div>
      <input type="text" id="medicalFollowUpDetails" formControlName="medicalFollowUpDetails" class="input-text"
        *ngIf="showMedicalFollowUp" placeholder="Cite detalhes">
    </div>

    <div class="form-group">
      <label>Faz acompanhamento com outro profissional?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="otherProfessionalFollowUp"
            (change)="toggleOtherProfessionalFollowUp($event)" value="nao" required> Não</label>
        <label><input type="radio" formControlName="otherProfessionalFollowUp"
            (change)="toggleOtherProfessionalFollowUp($event)" value="sim"> Sim</label>
      </div>
      <input type="text" id="otherProfessionalFollowUpDetails" formControlName="otherProfessionalFollowUpDetails"
        class="input-text" *ngIf="showOtherProfessionalFollowUp" placeholder="Cite detalhes">
    </div>

    <!-- Deficiências -->
    <h3 class="section-title">Deficiências</h3>
    <div class="form-group">
      <label>Possui alguma deficiência?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="hasDisability" (change)="toggleDisability($event)" value="nao"
            required>
          Não</label>
        <label><input type="radio" formControlName="hasDisability" (change)="toggleDisability($event)" value="sim">
          Sim</label>
      </div>
    </div>
    <div class="form-group" *ngIf="showDisability" formGroupName="disabilityDetails">
      <div class="checkbox-group">
        <label><input type="checkbox" id="fisica" formControlName="fisica" value="fisica"> Física</label>
        <input type="text" id="physicalDisabilityDetails" formControlName="physicalDisabilityDetails" class="input-text"
          placeholder="Cite detalhes">
        <label><input type="checkbox" id="visual" formControlName="visual" value="visual"> Visual</label>
        <input type="text" id="visualDisabilityDetails" formControlName="visualDisabilityDetails" class="input-text"
          placeholder="Cite detalhes">
        <label><input type="checkbox" id="auditiva" formControlName="auditiva" value="auditiva"> Auditiva</label>
        <input type="text" id="auditoryDisabilityDetails" formControlName="auditoryDisabilityDetails" class="input-text"
          placeholder="Cite detalhes">
        <label><input type="checkbox" id="intelectual" formControlName="intelectual" value="intelectual">
          Intelectual</label>
        <input type="text" id="intellectualDisabilityDetails" formControlName="intellectualDisabilityDetails"
          class="input-text" placeholder="Cite detalhes">
        <label><input type="checkbox" id="autismo" formControlName="autismo" value="autismo"> Autismo</label>
        <input type="text" id="autismDetails" formControlName="autismDetails" class="input-text"
          placeholder="Cite detalhes">
      </div>
    </div>

    <!-- Apresenta no Comportamento -->
    <h3 class="section-title">Apresenta no Comportamento</h3>
    <div class="form-group">
      <label>Crises de explosão em que agrida a si mesmo?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="selfAggression" (change)="toggleSelfAggression($event)" value="nao"
            required>
          Não</label>
        <label><input type="radio" formControlName="selfAggression" (change)="toggleSelfAggression($event)" value="sim">
          Sim</label>
      </div>
      <input type="text" id="selfAggressionDetails" formControlName="selfAggressionDetails" class="input-text"
        *ngIf="showSelfAggression" placeholder="Cite detalhes">
    </div>

    <div class="form-group">
      <label>Crises de explosão em que agrida os outros?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="othersAggression" (change)="toggleOthersAggression($event)"
            value="nao" required> Não</label>
        <label><input type="radio" formControlName="othersAggression" (change)="toggleOthersAggression($event)"
            value="sim">
          Sim</label>
      </div>
      <input type="text" id="othersAggressionDetails" formControlName="othersAggressionDetails" class="input-text"
        *ngIf="showOthersAggression" placeholder="Cite detalhes">
    </div>

    <div class="form-group">
      <label>Agitação psicomotora que atrapalhe a concentração?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="psychomotorAgitation" (change)="togglePsychomotorAgitation($event)"
            value="nao" required> Não</label>
        <label><input type="radio" formControlName="psychomotorAgitation" (change)="togglePsychomotorAgitation($event)"
            value="sim"> Sim</label>
      </div>
      <input type="text" id="psychomotorAgitationDetails" formControlName="psychomotorAgitationDetails"
        class="input-text" *ngIf="showPsychomotorAgitation" placeholder="Cite detalhes">
    </div>

    <div class="form-group">
      <label>Dificuldade de obedecer instruções verbais? De que tipo?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="verbalInstructions" (change)="toggleVerbalInstructions($event)"
            value="nao" required> Não</label>
        <label><input type="radio" formControlName="verbalInstructions" (change)="toggleVerbalInstructions($event)"
            value="sim">
          Sim</label>
      </div>
      <input type="text" id="verbalInstructionsDetails" formControlName="verbalInstructionsDetails" class="input-text"
        *ngIf="showVerbalInstructions" placeholder="Cite detalhes">
    </div>

    <div class="form-group">
      <label>Tendência a fugas?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="escapeTendency" (change)="toggleEscapeTendency($event)" value="nao"
            required>
          Não</label>
        <label><input type="radio" formControlName="escapeTendency" (change)="toggleEscapeTendency($event)" value="sim">
          Sim</label>
      </div>
      <input type="text" id="escapeTendencyDetails" formControlName="escapeTendencyDetails" class="input-text"
        *ngIf="showEscapeTendency" placeholder="Cite detalhes">
    </div>

    <div class="form-group">
      <label>Comportamento auto-destrutivo (cortes, arranhões, etc.)?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="selfDestructiveBehavior"
            (change)="toggleSelfDestructiveBehavior($event)" value="nao" required> Não</label>
        <label><input type="radio" formControlName="selfDestructiveBehavior"
            (change)="toggleSelfDestructiveBehavior($event)" value="sim"> Sim</label>
      </div>
      <input type="text" id="selfDestructiveBehaviorDetails" formControlName="selfDestructiveBehaviorDetails"
        class="input-text" *ngIf="showSelfDestructiveBehavior" placeholder="Cite detalhes">
    </div>

    <div class="form-group">
      <label>Crises de ansiedade ou pânico?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="anxietyCrisis" (change)="toggleAnxietyCrisis($event)" value="nao"
            required>
          Não</label>
        <label><input type="radio" formControlName="anxietyCrisis" (change)="toggleAnxietyCrisis($event)" value="sim">
          Sim</label>
      </div>
      <input type="text" id="anxietyCrisisDetails" formControlName="anxietyCrisisDetails" class="input-text"
        *ngIf="showAnxietyCrisis" placeholder="Cite detalhes">
    </div>

    <div class="form-group">
      <label>Dificuldade de comunicação com outras pessoas?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="communicationDifficulty"
            (change)="toggleCommunicationDifficulty($event)" value="nao" required> Não</label>
        <label><input type="radio" formControlName="communicationDifficulty"
            (change)="toggleCommunicationDifficulty($event)" value="sim"> Sim</label>
      </div>
      <input type="text" id="communicationDifficultyDetails" formControlName="communicationDifficultyDetails"
        class="input-text" *ngIf="showCommunicationDifficulty" placeholder="Cite detalhes">
    </div>

    <div class="form-group">
      <label>Dificuldades alimentares?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="feedingDifficulty" (change)="toggleFeedingDifficulty($event)"
            value="nao" required> Não</label>
        <label><input type="radio" formControlName="feedingDifficulty" (change)="toggleFeedingDifficulty($event)"
            value="sim">
          Sim</label>
      </div>
      <input type="text" id="feedingDifficultyDetails" formControlName="feedingDifficultyDetails" class="input-text"
        *ngIf="showFeedingDifficulty" placeholder="Cite detalhes">
    </div>

    <div class="form-group">
      <label>Dificuldades de sono?</label>
      <div class="radio-group">
        <label><input type="radio" formControlName="sleepDifficulty" (change)="toggleSleepDifficulty($event)"
            value="nao" required>
          Não</label>
        <label><input type="radio" formControlName="sleepDifficulty" (change)="toggleSleepDifficulty($event)"
            value="sim">
          Sim</label>
      </div>
      <input type="text" id="sleepDifficultyDetails" formControlName="sleepDifficultyDetails" class="input-text"
        *ngIf="showSleepDifficulty" placeholder="Cite detalhes">
    </div>

    <button type="button" (click)="submitForm()" class="button-primary">Salvar Ficha</button>

  </form>
  <!-- Modal de erro -->
  <div class="modal" *ngIf="showModal">
    <div class="modal-content">
      <h4>!</h4>
      <p>{{success ?"Ficha cadastrada": "Erro ao cadastrar ficha"}}</p>
      <button (click)="closeModal()" class="close-button">Fechar</button>
    </div>
  </div>
</div>