<div class="search-container">
    <div class="logo-section">
      <img src="../../assets/images/logo-centered.png" alt="RastreaMed Logo" class="logo" />
      <div class="content-section">
        <h2>Busca por Código</h2>
        <p>Insira abaixo o código de paciente para a consulta:</p>
        <div class="input-group">
          <input type="text" [(ngModel)]="searchCode" placeholder="Código para consulta" class="input-text" />
          <button class="search-button" (click)="onSearch()">Buscar</button>
        </div>
      </div>
    </div>
  
    <!-- Resultado do paciente -->
    <div class="result-card" *ngIf="patientData">
      <div class="card-header">
        <h3>{{ patientData.name }}</h3>
        <p><strong>Código SUS:</strong> {{ patientData.susCode }}</p>
        <p><strong>Idade:</strong> {{ patientData.age }} anos</p>
      </div>
  
      <div class="card-body">
        <!-- Dados do paciente -->
        <div class="card-info">
          <p><strong>Gênero:</strong> {{ patientData.gender === 'M' ? 'Masculino' : 'Feminino' }}</p>
          <p><strong>Tipo Sanguíneo:</strong> {{ patientData.bloodType }} 
              {{ patientData.rhFactor === 'PLUS' ? 'Positivo' : patientData.rhFactor === 'MINUS' ? 'Negativo' : '' }}
          </p>
          <p><strong>Peso:</strong> {{ patientData.weight }} kg</p>
          <p><strong>Altura:</strong> {{ patientData.height }} cm</p>
        </div>
  
        <!-- Histórico Patológico Pregresso (HPP) -->
        <div class="card-section">
          <h4>Histórico Patológico Pregresso (HPP)</h4>
          <table class="data-table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Tipo</th>
                <th>Data</th>
                <th>Observação</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let history of patientData.hpp">
                <td>{{ history.name }}</td>
                <td>{{ history.type }}</td>
                <td>{{ history.date }}</td>
                <td>{{ history.observation }}</td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <!-- Alergias -->
        <div class="card-section">
          <h4>Alergias</h4>
          <table class="data-table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Tipo</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let allergy of patientData.allergies">
                <td>{{ allergy.name }}</td>
                <td>{{ allergy.type }}</td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <!-- Tratamentos -->
        <div class="card-section">
          <h4>Tratamentos</h4>
          <table class="data-table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Dosagem</th>
                <th>Aplicação</th>
                <th>Frequência</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let treatment of patientData.treatments">
                <td>{{ treatment.name }}</td>
                <td>{{ treatment.dosage }}</td>
                <td>{{ treatment.aplication }}</td>
                <td>{{ treatment.frequence }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
    <!-- Modal de erro -->
    <div class="modal" *ngIf="showErrorModal">
      <div class="modal-content">
        <h4>!</h4>
        <p>Código não vinculado</p>
        <button (click)="closeModal()" class="close-button">Fechar</button>
      </div>
    </div>
  </div>
  