import { Component, OnInit } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.scss']
})
export class DocumentoComponent implements OnInit {
  data: any = {}
  constructor(private route: ActivatedRoute, private http: HttpClient) { }

  ngOnInit(): void {
    // Captura o queryParam 'refCode' da URL
    this.route.queryParamMap.subscribe(params => {
      const refCode = params.get('refCode');
      if (refCode) {
        this.fetchMedicalData(refCode);
      }
    });
  }

  fetchMedicalData(refCode: string): void {
    // Define a URL da API usando o refCode capturado
    const url = `https://api.rastreamed.com.br/api/medicalData/registration/${refCode}`;

    // Realiza a chamada HTTP GET para buscar os dados
    this.http.get(url).subscribe(
      (response: any) => {
        this.data = response; // Preenche a variável 'data' com o retorno da API
      },
      error => {
        console.error('Erro ao buscar dados médicos:', error);
      }
    );
  }

  downloadPDF() {
    const options = {
      margin: [0.1, 0.1], // Define uma margem de 0.1 polegada para todos os lados
      filename: 'ficha_medica - ' + this.data?.name + '.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true }, // Aumenta a resolução
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    };

    const element = document.getElementById('document-content');
    html2pdf().from(element).set(options).save();
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
}
