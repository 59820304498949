<div class="medical-record-form">
    <div id="document-content">

        <div class="medical-record-header">
            <div class="logo-section">
                <img src="assets/images/logo-escoteiros.webp" alt="Logo Escoteiros do Brasil" class="logo">
                <div class="header-text">
                    <h3>Escoteiros do Brasil</h3>
                    <p>PAXTU - Sistema de Informações e Gerenciamento de Unidades Escoteiras</p>
                </div>
                <img src="assets/images/logo-papa-leguas.png" alt="Logo Escoteiros do Brasil" class="logo2">
            </div>
            <div class="unit-section">
                <p>148/MG - PAPA-LÉGUAS</p>
            </div>
        </div>
        <div class="medical-record-form">
            <h2 class="title">Ficha Médica</h2>

            <form>

                <h3 class="section-title">Dados Pessoais</h3>
                <div class="personal-data">
                    <div class="column">
                        <label>Nome: <span>{{ data.name }}</span></label>
                    </div>
                    <div class="column">
                        <label>CPF: <span>{{ data.cpf }}</span></label>
                    </div>
                    <div class="column">
                        <label>Data de Nascimento: <span>{{ data.birthDate | date: 'dd/MM/yyyy' }}</span></label>
                    </div>

                    <div class="column">
                        <label>Sexo: <span>{{ data.gender === 'M' ? 'Masculino' : 'Feminino' }}</span></label>
                    </div>
                   
                    <div class="column">
                        <label>Tipo Sanguíneo: <span>{{ data.bloodType }}</span></label>
                    </div>

                    <div class="column">
                        <label>Fator RH: <span>{{ data.rhFactor }}</span></label>
                    </div>
                    <div class="column">
                        <label>Altura (cm): <span>{{ data.height }}</span></label>
                    </div>
                    <div class="column">
                        <label>Peso (kg): <span>{{ data.weight }}</span></label>
                    </div>
                    
                    <div class="column">
                        <label>Registro: <span>{{ data.code }}</span></label>
                    </div>
                    
                    <div class="column">
                        <label>Codigo da pulseira: <span>{{ data.registration }}</span></label>
                    </div>
                </div>

                <!-- Equipamentos de Auxílio -->
                <h3 class="section-title">Equipamentos de Auxílio</h3>
                <div class="form-group">
                    <label>Utiliza algum equipamento de auxílio? <span>{{ data.auxiliaryEquipment === 'sim' ? 'Sim' :
                            'Não'
                            }}</span></label>
                </div>
                <div class="form-group" *ngIf="data.auxiliaryEquipment === 'sim'">
                    <label>Óculos: <span>{{ data.equipment.oculos ? 'Sim' : 'Não' }}</span></label>
                    <label>Lentes de Contato: <span>{{ data.equipment.lentes ? 'Sim' : 'Não' }}</span></label>
                    <label>Aparelhos Dentários: <span>{{ data.equipment.dentarios ? 'Sim' : 'Não' }}</span></label>
                    <label>Sondas: <span>{{ data.equipment.sondas ? 'Sim' : 'Não' }}</span></label>
                    <label>Marcapasso: <span>{{ data.equipment.marcapasso ? 'Sim' : 'Não' }}</span></label>
                    <label>Aparelhos de Audição: <span>{{ data.equipment.audicao ? 'Sim' : 'Não' }}</span></label>
                    <label>Bomba de Insulina: <span>{{ data.equipment.bomba ? 'Sim' : 'Não' }}</span></label>
                    <label>Outros: <span>{{ data.equipment.outros || 'Não' }}</span></label>
                </div>
                <!-- Saúde Física -->
                <h3 class="section-title">Saúde Física</h3>
                <div class="physical-health">
                    <div class="column">
                        <label>Asma ou Bronquite: <span>{{ data.healthIssue.asma ? 'Sim' : 'Não' }}</span></label>
                    </div>
                    <div class="column">
                        <label>Rinite ou Sinusite: <span>{{ data.healthIssue.rinite ? 'Sim' : 'Não' }}</span></label>
                    </div>
                    <div class="column">
                        <label>Hipertensão: <span>{{ data.healthIssue.hipertensao ? 'Sim' : 'Não' }}</span></label>
                    </div>

                    <div class="column">
                        <label>Diabetes: <span>{{ data.healthIssue.diabetes ? 'Sim' : 'Não' }}</span></label>
                    </div>
                    <div class="column">
                        <label>Convulsões: <span>{{ data.healthIssue.convulsoes ? 'Sim' : 'Não' }}</span></label>
                    </div>
                    <div class="column">
                        <label>Problemas Dermatológicos: <span>{{ data.healthIssue.dermatologicos ? 'Sim' : 'Não'
                                }}</span></label>
                    </div>

                    <div class="column">
                        <label>Problemas Cardíacos: <span>{{ data.healthIssue.cardiacos ? 'Sim' : 'Não'
                                }}</span></label>
                    </div>
                    <div class="column">
                        <label>Problemas Renais: <span>{{ data.healthIssue.renais ? 'Sim' : 'Não' }}</span></label>
                    </div>
                    <div class="column">
                        <label>Problemas Reumatológicos: <span>{{ data.healthIssue.reumatologicos ? 'Sim' : 'Não'
                                }}</span></label>
                    </div>

                    <div class="column">
                        <label>Problemas Hematológicos: <span>{{ data.healthIssue.hematologicos ? 'Sim' : 'Não'
                                }}</span></label>
                    </div>
                    <div class="column">
                        <label>Outros: <span>{{ data.healthIssue.outros || 'Não' }}</span></label>
                    </div>
                </div>

                <!-- Medicamentos em Uso -->
                <h3 class="section-title">Medicamentos em Uso</h3>
                <div class="form-group">
                    <label>Faz uso de medicamentos? <span>{{ data.medications === 'sim' ? 'Sim' : 'Não'
                            }}</span></label>
                </div>
                <div class="form-group" *ngIf="data.medications === 'sim'">
                    <label>Lista de Medicamentos: <span>{{ data.medicationList || 'Não' }}</span></label>
                </div>

                <!-- Alergias -->
                <h3 class="section-title">Alergias</h3>
                <div class="allergies">
                    <div class="column">
                        <label>Picada de Inseto: <span>{{ data.allergyDetails.inseto =="true" ? "Sim" : 'Não' }}</span></label>
                    </div>
                    <div class="column">
                        <label>Medicamentos: <span>{{ data.allergyDetails.medicamento=="true" ? "Sim" : 'Não' }}</span></label>
                    </div>
                    <div class="column">
                        <label>Plantas: <span>{{ data.allergyDetails.plantas =="true"? "Sim" : 'Não' }}</span></label>
                    </div>

                    <div class="column">
                        <label>Alimentos: <span>{{ data.allergyDetails.alimentos=="true"? "Sim" : 'Não' }}</span></label>
                    </div>
                    <div class="column">
                        <label>Ácaros: <span>{{ data.allergyDetails.acaros =="true"? "Sim" : 'Não' }}</span></label>
                    </div>
                    <div class="column">
                        <label>Fungos: <span>{{ data.allergyDetails.fungos =="true"? "Sim" : 'Não' }}</span></label>
                    </div>

                    <div class="column">
                        <label>Outros: <span>{{ data.allergyDetails.outros =="true"? "Sim" : 'Não' }}</span></label>
                    </div>
                </div>

                <!-- Informações Gerais e Saúde Mental -->
                <h3 class="section-title">Informações Gerais</h3>
                <div class="form-group">
                    <label>Possui impedimento físico? <span>{{ data.physicalImpediment === 'sim' ? 'Sim' : 'Não'
                            }}</span></label>
                    <span *ngIf="data.physicalImpediment === 'sim'">Detalhes: {{ data.physicalImpedimentDetails
                        }}</span>
                </div>

                <!-- Restrições Alimentares -->
                <h3 class="section-title">Restrições Alimentares</h3>
                <div class="form-group">
                    <label>Restrições a alimentos? <span>{{ data.foodRestrictions === 'sim' ? 'Sim' : 'Não'
                            }}</span></label>
                    <span *ngIf="data.foodRestrictions === 'sim'">Detalhes: {{ data.foodRestrictionDetails }}</span>
                </div>

                <!-- Saúde Mental -->
                <h3 class="section-title">Saúde Mental</h3>
                <div class="form-group">
                    <label>Apresenta Distúrbio de Comportamento? <span>{{ data.behavioralDisorder === 'sim' ? 'Sim' :
                            'Não'
                            }}</span></label>
                    <span *ngIf="data.behavioralDisorder === 'sim'">Detalhes: {{ data.behavioralDisorderDetails
                        }}</span>
                </div>

                <div class="form-group">
                    <label>Faz uso de medicação para este transtorno? <span>{{ data.behavioralMedication === 'sim' ?
                            'Sim' :
                            'Não' }}</span></label>
                    <span *ngIf="data.behavioralMedication === 'sim'">Detalhes: {{ data.behavioralMedicationDetails
                        }}</span>
                </div>

                <div class="form-group">
                    <label>Faz acompanhamento com psicólogo? <span>{{ data.psychologistFollowUp === 'sim' ? 'Sim' :
                            'Não'
                            }}</span></label>
                    <span *ngIf="data.psychologistFollowUp === 'sim'">Detalhes: {{ data.psychologistFollowUpDetails
                        }}</span>
                </div>

                <div class="form-group">
                    <label>Faz acompanhamento com médico? <span>{{ data.medicalFollowUp === 'sim' ? 'Sim' : 'Não'
                            }}</span></label>
                    <span *ngIf="data.medicalFollowUp === 'sim'">Detalhes: {{ data.medicalFollowUpDetails }}</span>
                </div>

                <div class="form-group">
                    <label>Faz acompanhamento com outro profissional? <span>{{ data.otherProfessionalFollowUp === 'sim'
                            ?
                            'Sim' : 'Não' }}</span></label>
                    <span *ngIf="data.otherProfessionalFollowUp === 'sim'">Detalhes: {{
                        data.otherProfessionalFollowUpDetails }}</span>
                </div>
                <!-- Deficiências -->
                <h3 class="section-title">Deficiências</h3>
                <div class="form-group">
                    <label>Possui alguma deficiência? <span>{{ data.hasDisability === 'sim' ? 'Sim' : 'Não'
                            }}</span></label>
                </div>
                <div class="form-group" *ngIf="data.hasDisability === 'sim'">
                    <label>Física: <span>{{ data.disabilityDetails.fisica ? "Sim" : 'Não' }}</span></label>
                    <label>Visual: <span>{{ data.disabilityDetails.visual ? "Sim" : 'Não' }}</span></label>
                    <label>Auditiva: <span>{{ data.disabilityDetails.auditiva ? "Sim" : 'Não' }}</span></label>
                    <label>Intelectual: <span>{{ data.disabilityDetails.intelectual ? "Sim" : 'Não' }}</span></label>
                    <label>Autismo: <span>{{ data.disabilityDetails.autismo ? "Sim" : 'Não' }}</span></label>
                </div>

                <!-- Comportamento -->
                <h3 class="section-title">Comportamento</h3>
                <div class="form-group">
                    <label>Crises de explosão em que agrida a si mesmo? <span>{{ data.selfAggression === 'sim' ? 'Sim' :
                            'Não' }}</span></label>
                    <span *ngIf="data.selfAggression === 'sim'">Detalhes: {{ data.selfAggressionDetails }}</span>
                </div>

                <div class="form-group">
                    <label>Crises de explosão em que agrida os outros? <span>{{ data.othersAggression === 'sim' ? 'Sim'
                            :
                            'Não' }}</span></label>
                    <span *ngIf="data.othersAggression === 'sim'">Detalhes: {{ data.othersAggressionDetails }}</span>
                </div>

                <div class="form-group">
                    <label>Agitação psicomotora que atrapalhe a concentração? <span>{{ data.psychomotorAgitation ===
                            'sim' ?
                            'Sim' : 'Não' }}</span></label>
                    <span *ngIf="data.psychomotorAgitation === 'sim'">Detalhes: {{ data.psychomotorAgitationDetails
                        }}</span>
                </div>

                <div class="form-group">
                    <label>Dificuldade de obedecer instruções verbais? <span>{{ data.verbalInstructions === 'sim' ?
                            'Sim' :
                            'Não' }}</span></label>
                    <span *ngIf="data.verbalInstructions === 'sim'">Detalhes: {{ data.verbalInstructionsDetails
                        }}</span>
                </div>

                <div class="form-group">
                    <label>Tendência a fugas? <span>{{ data.escapeTendency === 'sim' ? 'Sim' : 'Não' }}</span></label>
                    <span *ngIf="data.escapeTendency === 'sim'">Detalhes: {{ data.escapeTendencyDetails }}</span>
                </div>

                <div class="form-group">
                    <label>Comportamento auto-destrutivo (cortes, arranhões, etc.)? <span>{{
                            data.selfDestructiveBehavior
                            === 'sim' ? 'Sim' : 'Não' }}</span></label>
                    <span *ngIf="data.selfDestructiveBehavior === 'sim'">Detalhes: {{
                        data.selfDestructiveBehaviorDetails
                        }}</span>
                </div>

                <div class="form-group">
                    <label>Crises de ansiedade ou pânico? <span>{{ data.anxietyCrisis === 'sim' ? 'Sim' : 'Não'
                            }}</span></label>
                    <span *ngIf="data.anxietyCrisis === 'sim'">Detalhes: {{ data.anxietyCrisisDetails }}</span>
                </div>

                <div class="form-group">
                    <label>Dificuldade de comunicação com outras pessoas? <span>{{ data.communicationDifficulty ===
                            'sim' ?
                            'Sim' : 'Não' }}</span></label>
                    <span *ngIf="data.communicationDifficulty === 'sim'">Detalhes: {{
                        data.communicationDifficultyDetails
                        }}</span>
                </div>

                <div class="form-group">
                    <label>Dificuldades alimentares? <span>{{ data.feedingDifficulty === 'sim' ? 'Sim' : 'Não'
                            }}</span></label>
                    <span *ngIf="data.feedingDifficulty === 'sim'">Detalhes: {{ data.feedingDifficultyDetails }}</span>
                </div>

                <div class="form-group">
                    <label>Dificuldades de sono? <span>{{ data.sleepDifficulty === 'sim' ? 'Sim' : 'Não'
                            }}</span></label>
                    <span *ngIf="data.sleepDifficulty === 'sim'">Detalhes: {{ data.sleepDifficultyDetails }}</span>
                </div>

            </form>
        </div>
    </div>
    <button class="download-button" (click)="downloadPDF()">Baixar como PDF</button>

</div>