<div class="info-container">
    <div class="image-section">
      <img src="../../assets/images/doctor-image.png" alt="Doctor with Tablet" class="info-image"/>
    </div>
    <div class="text-section">
      <img src="../../assets/images/logo-centered.png" alt="RastreaMed Logo" class="info-logo"/>
      <h2>Informações Rastreadas</h2>
      <p>{{'contato@rastreamed.com.br'}}</p>
      <p>{{'(35) 9 9143-7936'}}</p>
    </div>
  </div>
  