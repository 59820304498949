import { Component, inject } from '@angular/core';
import { SearchService } from '../search.service'; // Importe o serviço
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  searchCode: string = '';
  patientData: any = null;
  showErrorModal: boolean = false;
  private route: any = inject(ActivatedRoute);

  constructor(private searchService: SearchService) { }


  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      const refcode: string = params['refCode'];
      if (refcode) {
        this.loadSharedData(refcode)
      }
    });
  }
  
  loadSharedData(refcode: string) {
    if (refcode) {
      this.searchService.find(refcode).subscribe((data: any) => {
        this.patientData = data;
      });
    }
  }

  onSearch(): void {
    if (this.searchCode) {
      this.searchService.getPatientData(this.searchCode).subscribe(
        (data) => {
          if (data) {
            this.patientData = data;
          } else {
            this.showErrorModal = true;
          }
        },
        (error) => {
          console.error('Erro ao buscar dados:', error);
          this.showErrorModal = true;
        }
      );
    }
  }

  closeModal(): void {
    this.showErrorModal = false;
  }
}
