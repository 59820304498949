import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PresentationComponent } from './presentation/presentation.component';
import { InfoComponent } from './info/info.component';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './search/search.component';
import { MemberMedicalRecordComponent } from './member-medical-record/member-medical-record.component';
import { DocumentoComponent } from './documento/documento.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'fale-conosco', component: InfoComponent },
  { path: 'info', component: InfoComponent },
  { path: 'busca', component: SearchComponent },
  { path: 'ficha-medica', component: MemberMedicalRecordComponent },
  { path: 'documento-ficha-medica', component: DocumentoComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
